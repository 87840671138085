@import "nlib/config";

.selectTaskStatus {
  [data-value] {
    padding-left: 0.625rem;
    &::before {
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      margin-right: $contentDefaultIndent;
      border: 0.25rem solid mix($uiMediumDarkColor, $uiWhiteColor, 15);
      border-radius: 50%;
      background-color: lighten($uiMediumDarkColor, 22);
    }
  }
  [data-value=inProgress] {
    &::before {
      border-color: mix($uiWarningColor, $uiWhiteColor, 15);
      background-color: $uiWarningColor;
    }
  }
  [data-value=onHold] {
    &::before {
      border-color: mix($uiNegativeColor, $uiWhiteColor, 15);
      background-color: $uiNegativeColor;
    }
  }
  [data-value=completed] {
    &::before {
      border-color: mix($uiPositiveColor, $uiWhiteColor, 15);
      background-color: $uiPositiveColor;
    }
  }
  .dropDownClassName {
    [data-value] {
      padding-left: 0.25rem;
    }
  }
}
