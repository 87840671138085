@import "nlib/config";

.tasksPage {
  .pageHeader {
    margin-bottom: 0;
  }
  .subHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent * 2;
    .filters {
      display: flex;
      margin-left: $contentDefaultIndent * 1.5;
      .reset {
        margin-right: $contentDefaultIndent * 1.5;
      }
      .search {
        width: 240px;
      }
      .filter {
        width: 180px;
        height: 2.5rem;
        margin-left: $contentDefaultIndent * 1.5;
      }
      .button {
        min-width: 140px;
        margin-left: $contentDefaultIndent * 1.5;
      }
    }
  }
  .content {
    display: flex;
    flex: auto;
  }
  .emptyState {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 8rem;
    font-size: 1rem;
    h4 {
      max-width: 370px;
      color: $uiDarkColor;
      text-align: center;
    }
    button {
      margin-top: $contentDefaultIndent;
    }
  }
}
