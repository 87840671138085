@import "nlib/config";

.monthsTabs {
  position: relative;
  width: fit-content;
  max-width: 100%;
  padding: $contentDefaultIndent / 2;
  overflow: hidden;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  .wrapper {
    display: flex;
    position: relative;
    transform: translate(0, 0);
    transition: transform 0.2s;
  }
  .tab {
    display: flex;
    position: relative;
    align-items: center;
    padding: $contentDefaultIndent $contentDefaultIndent * 2;
    border-radius: $uiBorderRadius;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken($uiBackgroundColor, 2);
    }
    &.active {
      background-color: $uiBackgroundColor;
    }
    &.completed {
      .label {
        color: $uiMediumDarkColor;
        font-weight: normal !important;
        text-decoration: line-through;
      }
    }
    &:not(.active):not(:hover) {
      + .tab {
        &:not(.active):not(:hover) {
          &::before {
            content: "";
            position: absolute;
            top: 0.5rem;
            bottom: 0.5rem;
            left: 0;
            border-left: 1px solid $uiBorderColor;
          }
        }
      }
    }
    .label {
      line-height: 1.25rem;
    }
    .badge {
      margin-left: $contentDefaultIndent;
    }
    .transactions {
      color: lighten($uiMediumDarkColor, 10);
      font-size: 0.6875rem;
      line-height: 0.75rem;
    }
    .notification {
      height: 18px;
      margin-left: $contentDefaultIndent;
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid transparent;
      border-radius: 8px;
      background-color: $uiNegativeColor;
      color: $uiWhiteColor;
      font-weight: bold;
      line-height: 13px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      svg {
        font-size: 0.75rem;
      }
    }
  }
  .leftArrow,
  .rightArrow {
    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 100%;
    color: $uiMediumDarkColor;
    font-size: 1.5rem;
    cursor: pointer;
    &:not([disabled]):hover {
      color: $uiBlackColor;
    }
    &[disabled] {
      opacity: 0 !important;
      cursor: default;
      pointer-events: initial !important;
    }
  }
  .leftArrow {
    left: 0;
    background-image: linear-gradient(to right, $uiWhiteColor 50%, transparent);
  }
  .rightArrow {
    right: 0;
    background-image: linear-gradient(to left, $uiWhiteColor 50%, transparent);
  }
}
