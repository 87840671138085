@import "nlib/config";

.listView {
  flex: auto;
  .noData {
    height: 100%;
  }
  .header {
    display: flex;
    position: sticky;
    z-index: 1;
    top: 0;
    padding: $contentDefaultIndent 0;
    padding-left: 3px;
    background-image: linear-gradient($uiBackgroundColor, $uiBackgroundColor 80%, transparent);
    color: $uiMediumDarkColor;
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .checkBoxWrapper {
      width: 42px;
    }
    .content {
      flex: 60;
      width: 60px;
    }
    .status {
      flex: 20;
      width: 20px;
      padding-right: $contentDefaultIndent;
    }
    .startDate,
    .dueDate {
      flex: 10;
      width: 10px;
      min-width: 90px;
    }
    .user {
      flex: 20;
      width: 20px;
      padding: 0 $contentDefaultIndent;
    }
    .createdBy {
      flex: 26;
      width: 26px;
      padding: 0 $contentDefaultIndent;
    }
    .info {
      display: flex;
      flex: 16;
      width: 16px;
      min-width: 146px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $contentDefaultIndent * 1.5;
  }
}
