@import "nlib/config";

.monthsTabs {
  position: relative;
  min-width: 170px;
  margin-right: $contentDefaultIndent * 1.5;
  .title {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: $contentDefaultIndent 0 $contentDefaultIndent $contentDefaultIndent * 2;
    background-image: linear-gradient($uiBackgroundColor, $uiBackgroundColor 80%, transparent);
    color: $uiMediumDarkColor;
  }
  .content {
    padding: $contentDefaultIndent / 2;
    overflow: hidden;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
  .tab {
    display: flex;
    position: relative;
    align-items: center;
    padding: $contentDefaultIndent $contentDefaultIndent * 1.5;
    border-radius: $uiBorderRadius;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken($uiBackgroundColor, 2);
    }
    &.active {
      background-color: $uiBackgroundColor;
    }
    &.completed {
      .label {
        color: $uiMediumDarkColor;
        font-weight: normal !important;
        text-decoration: line-through;
      }
    }
    .label {
      margin-right: auto;
      line-height: 1.25rem;
    }
    .badge {
      margin-left: $contentDefaultIndent;
    }
    .transactions {
      color: lighten($uiMediumDarkColor, 10);
      font-size: 0.6875rem;
      line-height: 0.75rem;
    }
    .notification {
      height: 18px;
      margin-left: $contentDefaultIndent;
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid transparent;
      border-radius: 8px;
      background-color: $uiNegativeColor;
      color: $uiWhiteColor;
      font-weight: bold;
      line-height: 13px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      svg {
        font-size: 0.75rem;
      }
    }
  }
  .leftArrow,
  .rightArrow {
    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 100%;
    color: $uiMediumDarkColor;
    font-size: 1.5rem;
    cursor: pointer;
    &:not([disabled]):hover {
      color: $uiBlackColor;
    }
    &[disabled] {
      opacity: 0 !important;
      cursor: default;
      pointer-events: initial !important;
    }
  }
  .leftArrow {
    left: 0;
    background-image: linear-gradient(to right, $uiWhiteColor 50%, transparent);
  }
  .rightArrow {
    right: 0;
    background-image: linear-gradient(to left, $uiWhiteColor 50%, transparent);
  }
}
