@import "nlib/config";

.boardView {
  position: relative;
  flex: auto;
  margin-top: $contentDefaultIndent * 2;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  &.scrollable {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .scroll {
    display: flex;
    height: 100%;
    padding: $contentDefaultIndent * 1.5;
    overflow-x: auto;
  }
  .arrowLeft,
  .arrowRight {
    display: flex;
    position: absolute;
    z-index: 1;
    top: $contentDefaultIndent * 1.5;
    bottom: $contentDefaultIndent * 1.5;
    align-items: center;
    width: 4rem;
    color: $uiMediumDarkColor;
    font-size: 2rem;
    cursor: pointer;
    svg {
      transform: scale(0.5, 1);
    }
    &:hover {
      color: $uiDarkColor;
    }
  }
  .arrowLeft {
    left: 0;
    padding-left: 0.5rem;
    background-image: linear-gradient(to right, $uiWhiteColor $contentDefaultIndent, transparent);
  }
  .arrowRight {
    right: 0;
    justify-content: flex-end;
    padding-right: 0.5rem;
    background-image: linear-gradient(to left, $uiWhiteColor $contentDefaultIndent, transparent);
  }
  * {
    user-select: none;
  }
}
