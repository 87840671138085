@import "nlib/config";

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 18rem;
  border-radius: $uiBorderRadius;
  background-color: $uiBackgroundColor;
  .notAllowedOverlay {
    display: none;
  }
  &.draggedOver {
    box-shadow: 0 0 0 0.125rem $uiMediumDarkColor;
  }
  &.notAllowed {
    position: relative;
    box-shadow: none;
    .notAllowedOverlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($uiWhiteColor, 0.75);
      pointer-events: none;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8rem;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
        border: 0.25rem solid darken($uiBackgroundColor, 5);
      }
      &::after {
        height: 8rem;
        transform: translate(-50%, -50%);
        border: 0.5rem solid darken($uiBackgroundColor, 5);
        border-radius: 50%;
      }
    }
  }
  + .column {
    margin-left: $contentDefaultIndent * 1.5;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $contentDefaultIndent * 1.5;
    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      &[data-status] {
        &::before {
          content: "";
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin-right: $contentDefaultIndent;
          border: 0.25rem solid mix($uiMediumDarkColor, $uiWhiteColor, 15);
          border-radius: 50%;
          background-color: lighten($uiMediumDarkColor, 22);
        }
      }
      &[data-status=inProgress] {
        &::before {
          border-color: mix($uiWarningColor, $uiWhiteColor, 15);
          background-color: $uiWarningColor;
        }
      }
      &[data-status=onHold] {
        &::before {
          border-color: mix($uiNegativeColor, $uiWhiteColor, 15);
          background-color: $uiNegativeColor;
        }
      }
      &[data-status=completed] {
        &::before {
          border-color: mix($uiPositiveColor, $uiWhiteColor, 15);
          background-color: $uiPositiveColor;
        }
      }
      .badge {
        margin-left: $contentDefaultIndent;
      }
    }
    .addButton {
      height: fit-content;
      color: $uiMediumDarkColor;
      cursor: pointer;
      svg {
        font-size: 1.5rem;
      }
      &:hover {
        color: $uiFontColor;
      }
    }
  }
  .list {
    position: relative;
    flex: auto;
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: $contentDefaultIndent * 1.5;
      left: $contentDefaultIndent * 1.5;
      height: $contentDefaultIndent * 1.5;
    }
    .scroll {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 $contentDefaultIndent $contentDefaultIndent;
      overflow: auto;
      scrollbar-color: rgba($uiDarkColor, 0.4) rgba($uiBlackColor, 0.05);
      scrollbar-width: thin;
    }
  }
}
