@import "nlib/config";

.taskContent {
  .title {
    margin-bottom: $contentDefaultIndent;
    font-size: 1rem;
    font-weight: 500;
  }
  .completeButton {
    justify-content: flex-start;
    margin-bottom: $contentDefaultIndent * 2;
    font-size: 0.75rem;
    > span {
      flex: auto;
      margin-left: $contentDefaultIndent;
    }
  }
  .attachments {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .statusSelect {
    margin-bottom: $contentDefaultIndent * 2;
    [data-value] {
      padding-left: 0.625rem;
      &::before {
        content: "";
        width: 1.25rem;
        height: 1.25rem;
        margin-right: $contentDefaultIndent;
        border: 0.25rem solid mix($uiMediumDarkColor, $uiWhiteColor, 15);
        border-radius: 50%;
        background-color: lighten($uiMediumDarkColor, 22);
      }
    }
    [data-value=inProgress] {
      &::before {
        border-color: mix($uiWarningColor, $uiWhiteColor, 15);
        background-color: $uiWarningColor;
      }
    }
    [data-value=onHold] {
      &::before {
        border-color: mix($uiNegativeColor, $uiWhiteColor, 15);
        background-color: $uiNegativeColor;
      }
    }
    [data-value=completed] {
      &::before {
        border-color: mix($uiPositiveColor, $uiWhiteColor, 15);
        background-color: $uiPositiveColor;
      }
    }
    .dropDownClassName {
      [data-value] {
        padding-left: 0.25rem;
      }
    }
  }
  .header {
    margin-bottom: $contentDefaultIndent * 2;
    .info {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: $contentDefaultIndent;
      .infoItem {
        width: fit-content;
        padding-right: $contentDefaultIndent * 1.5;
        padding-bottom: $contentDefaultIndent;
        overflow: hidden;
        font-size: 0.75rem;
        line-height: 1.25rem;
        .infoItemTitle {
          margin-bottom: $contentDefaultIndent / 2;
          font-weight: 500;
        }
        .infoItemValue {
          overflow: hidden;
          color: $uiMediumDarkColor;
          text-overflow: ellipsis;
          white-space: nowrap;
          svg {
            color: $uiNeutralColor;
            font-size: 0.875rem;
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .showMoreText {
    position: relative;
    transform: translate(0, - $contentDefaultIndent * 2);
    color: $uiPrimaryColor;
    font-size: 0.75rem;
    text-align: center;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 16px;
      background-image: linear-gradient(transparent, $uiWhiteColor);
    }
  }
  .text {
    position: relative;
    max-height: 240px;
    margin-bottom: $contentDefaultIndent * 2;
    overflow: hidden;
    line-height: 1.25rem;
    .markdown {
      overflow: hidden;
      font-size: 0.8125rem;
      word-break: break-all;
    }
    ul,
    ol {
      padding-left: 1rem;
    }
    p {
      margin-bottom: $contentDefaultIndent * 2;
      &:last-child {
        margin-bottom: 0;
      }
    }
    h1 {
      font-size: 2rem;
      font-weight: 500;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }
    h3 {
      font-size: 1.25rem;
      font-weight: 500;
    }
    h4 {
      font-size: 1rem;
      font-weight: 500;
    }
    h5 {
      font-size: 0.8125rem;
      font-weight: 500;
    }
    h6 {
      font-size: 0.8125rem;
      font-weight: 500;
    }
    code {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      border-radius: $uiBorderRadius;
      background-color: $uiBackgroundColor;
    }
    pre {
      margin-bottom: $contentDefaultIndent * 2;
      padding: 0.25rem 0.5rem;
      border-radius: $uiBorderRadius;
      background-color: $uiBackgroundColor;
      line-height: 1.5;
      white-space: pre;
      code {
        padding: 0;
        border-radius: 0;
        background-color: transparent;
      }
    }
    img {
      max-width: 100%;
    }
    &.showAllText {
      max-height: none;
      + .showMoreText {
        &::before {
          display: none;
        }
      }
    }
  }
  .col {
    + .col {
      margin-top: $contentDefaultIndent * 2;
    }
  }
  .activity {
    margin-bottom: 0;
  }
  .contentWrap {
    display: flex;
    flex-direction: column;
  }
  .toggleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .repeatOptions {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .actions {
    display: flex;
    margin-top: $contentDefaultIndent * 1.5;
    padding: $contentDefaultIndent;
    border-radius: $uiBorderRadius;
    background-color: $uiBackgroundColor;
    button {
      flex: 1;
      &:not(:last-child) {
        margin-right: $contentDefaultIndent;
      }
    }
  }
  .targetUser {
    margin-bottom: $contentDefaultIndent * 1.5;
  }
}

[data-mobile] {
  @media (min-width: $viewportSizeMedium) {
    .contentWrap {
      flex-direction: row;
      .col {
        width: 50%;
        + .col {
          margin-top: 0;
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
  }
}
