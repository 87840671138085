@import "nlib/config";

.filterByUser {
  height: 100%;
  .toggle {
    height: 100%;
    overflow: hidden;
    button {
      width: 100%;
      height: 100%;
      overflow: hidden;
      svg {
        color: $uiMediumDarkColor;
        font-size: 1rem;
      }
      .label {
        flex: auto;
        margin-left: $contentDefaultIndent / 2;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        &.placeholder {
          color: $uiMediumDarkColor;
        }
      }
    }
  }
}
.dropDownContent {
  width: 280px;
  padding: $contentDefaultIndent * 1.5;
  button {
    justify-content: flex-start;
    width: 100%;
  }
  .divider {
    margin: $contentDefaultIndent * 2 0;
  }
  .label {
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .badge {
    margin-left: $contentDefaultIndent;
  }
  .title {
    margin-top: $contentDefaultIndent * 1.5;
    margin-left: $contentDefaultIndent;
    color: $uiMediumDarkColor;
    font-size: 12px;
  }
  .menuItem {
    border-radius: $uiBorderRadius;
    cursor: pointer;
    &.myTasks {
      margin-bottom: $contentDefaultIndent;
      padding-right: $contentDefaultIndent - 1;
      border: 1px solid $uiBorderColor;
    }
    &:hover {
      background-color: $uiBackgroundColor;
    }
  }
}
