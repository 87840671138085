@import "nlib/config";

.formContent {
  .head {
    display: flex;
    align-items: center;
    margin-bottom: $contentDefaultIndent * 2;
    .title {
      margin-right: $contentDefaultIndent * 2;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;
    }
    .close {
      margin-left: auto;
      cursor: pointer;
    }
  }
  .selectStatus {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .label {
    margin-top: $contentDefaultIndent * 2;
    margin-bottom: $contentDefaultIndent;
    font-size: 0.8125rem;
    line-height: 0.9375rem;
  }
  .formElement {
    display: block;
    width: 100%;
  }
  textarea {
    height: 7rem;
    min-height: 3rem;
  }
  .row {
    display: flex;
    .col {
      flex: 1;
      + .col {
        margin-left: $contentDefaultIndent * 1.5;
      }
    }
  }
  .formRow {
    + .formRow {
      margin-top: $contentDefaultIndent * 2;
    }
  }
  .footer {
    button {
      svg {
        font-size: 1rem;
        + span {
          margin-left: 0.3em;
        }
      }
    }
  }
  .contentWrap {
    display: flex;
    flex-direction: column;
    .colTablet {
      + .colTablet {
        margin-top: $contentDefaultIndent * 2;
      }
    }
  }
}

[data-mobile] {
  @media (min-width: $viewportSizeMedium) {
    .formContent {
      .contentWrap {
        flex-direction: row;
        .colTablet {
          width: 50%;
          + .colTablet {
            margin-top: 0;
            margin-left: $contentDefaultIndent * 2;
          }
        }
      }
    }
  }
}
