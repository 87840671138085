@import "nlib/config";

.card {
  padding: $contentDefaultIndent * 1.5;
  transition: box-shadow 300ms ease-out;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  cursor: pointer;
  &.dragging {
    opacity: 0.5;
  }
  &.selected {
    box-shadow: 0 0 0 2px $uiNeutralColor inset;
  }
  &:hover {
    box-shadow: 0 0 0 2px $uiPrimaryColor inset;
  }
  + .card {
    margin-top: $contentDefaultIndent;
  }
  .preview {
    margin-bottom: $contentDefaultIndent;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
  }
  .createdBy {
    margin-bottom: $contentDefaultIndent;
    color: $uiMediumDarkColor;
    font-size: 0.75rem;
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    .infoItem {
      margin-right: $contentDefaultIndent * 2;
      margin-bottom: $contentDefaultIndent;
      overflow: hidden;
      .infoItemTitle {
        color: $uiMediumDarkColor;
        font-size: 0.75rem;
      }
      .infoItemValue {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        svg {
          color: $uiMediumDarkColor;
          font-size: 1rem;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    .item {
      &:last-child {
        margin-left: auto;
      }
      svg {
        font-size: 1rem;
      }
      &.attachments {
        min-width: 3.25rem;
      }
      &.comments {
        min-width: 3.25rem;
      }
    }
    .remove {
      color: $uiNegativeColor;
      cursor: pointer;
      &:hover {
        color: $uiFontColor;
      }
    }
  }
}
