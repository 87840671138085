@import "nlib/config";

.taskListItem {
  display: flex;
  position: relative;
  align-items: center;
  height: 3rem;
  transition: box-shadow 300ms ease-out;
  border-left: 3px solid $uiDarkColor;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  cursor: pointer;
  + .taskListItem {
    margin-top: $contentDefaultIndent * 1.5;
  }
  .checkBoxWrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 $contentDefaultIndent * 1.5 0 $contentDefaultIndent * 2;
  }
  .content {
    flex: 60;
    width: 60px;
    font-weight: 500;
    div {
      margin-right: $contentDefaultIndent;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .status {
    flex: 20;
    width: 20px;
    padding-right: $contentDefaultIndent;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    [data-status] {
      &::before {
        content: "";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin-right: $contentDefaultIndent;
        border: 0.25rem solid mix($uiMediumDarkColor, $uiWhiteColor, 15);
        border-radius: 50%;
        background-color: lighten($uiMediumDarkColor, 22);
      }
    }
    [data-status=inProgress] {
      &::before {
        border-color: mix($uiWarningColor, $uiWhiteColor, 15);
        background-color: $uiWarningColor;
      }
    }
    [data-status=onHold] {
      &::before {
        border-color: mix($uiNegativeColor, $uiWhiteColor, 15);
        background-color: $uiNegativeColor;
      }
    }
    [data-status=completed] {
      &::before {
        border-color: mix($uiPositiveColor, $uiWhiteColor, 15);
        background-color: $uiPositiveColor;
      }
    }
  }
  .startDate,
  .dueDate {
    flex: 10;
    width: 10px;
    min-width: 90px;
    min-height: 1rem;
  }
  .user {
    flex: 20;
    width: 20px;
    padding: 0 $contentDefaultIndent;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      svg {
        color: $uiMediumDarkColor;
        font-size: 0.875rem;
      }
    }
  }
  .createdBy {
    flex: 26;
    width: 26px;
    padding: 0 $contentDefaultIndent;
    > div {
      overflow: hidden;
      color: $uiMediumDarkColor;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span + span {
      &::before {
        content: ", ";
      }
    }
  }
  .info {
    display: flex;
    flex: 16;
    width: 16px;
    min-width: 146px;
    .item {
      display: flex;
      align-items: center;
      width: 42px;
      color: $uiDarkColor;
      font-size: 0.75rem;
      line-height: 1.25rem;
      cursor: pointer;
      svg {
        font-size: 1.125rem;
      }
      &.primary {
        svg {
          color: $uiPrimaryColor;
        }
      }
      &.attachments {
        min-width: 3.25rem;
      }
      &.comments {
        min-width: 3.25rem;
      }
      .remove {
        padding: 0 $contentDefaultIndent;
        color: $uiNegativeColor;
      }
    }
  }
  &.inProgress {
    border-left-color: $uiWarningColor;
  }
  &.onHold {
    border-left-color: $uiNegativeColor;
  }
  &.completed {
    border-left-color: $uiPositiveColor;
    .checkBoxWrapper {
      .checkBox {
        > div {
          border-color: $uiPositiveColor;
          background-color: $uiPositiveColor;
        }
      }
    }
    .content {
      text-decoration: line-through;
    }
    .content,
    .startDate,
    .dueDate,
    .user,
    .createdBy {
      opacity: 0.35;
    }
  }
  &:hover {
    box-shadow: 1px 1px 6px rgba($uiBlackColor, 0.08);
  }
  &.selected {
    box-shadow: 2px 2px 12px rgba($uiBlackColor, 0.2);
    .content {
      font-weight: 500;
    }
  }
  &.autoTask {
    .checkBoxWrapper {
      > div {
        cursor: not-allowed !important;
        div {
          background-color: $uiWhiteColor;
        }
      }
    }
  }
}
