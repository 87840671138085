@import "nlib/config";

.autoTaskContent {
  margin-bottom: $contentDefaultIndent * 1.5;
  .stats {
    padding: $contentDefaultIndent * 1.5;
    border-radius: $uiBorderRadius;
    background-color: $uiBackgroundColor;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent;
    padding-bottom: $contentDefaultIndent;
    border-bottom: 1px solid $uiBorderColor;
    .title {
      font-weight: 500;
    }
    .count {
      color: $uiMediumDarkColor;
      text-transform: lowercase;
    }
  }
  .content {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $contentDefaultIndent / 2 0;
      .title {
        font-weight: 500;
      }
    }
    .empty {
      padding: $contentDefaultIndent / 2 0;
      color: $uiMediumDarkColor;
      text-align: center;
    }
  }
}
.button {
  margin-top: $contentDefaultIndent * 1.5;
  svg {
    font-size: 1.2em;
  }
}
