@import "nlib/config";

.taskRepeatOptions {
  .toggleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .row {
    display: flex;
    max-width: 100%;
    + .row {
      margin-top: $contentDefaultIndent * 1.5;
    }
    .col {
      flex: none;
    }
    .colOne {
      flex: 1;
    }
    .colTwo {
      flex: 2;
    }
    .col,
    .colOne,
    .colTwo {
      + .col,
      + .colOne,
      + .colTwo {
        padding-left: $contentDefaultIndent * 1.5;
      }
    }
  }
  .label {
    display: flex;
    align-items: center;
    width: 7rem;
    height: 100%;
    padding-left: $contentDefaultIndent * 1.5;
    border-radius: $uiBorderRadius;
    background-color: $uiBackgroundColor;
    &.cursorPointer {
      cursor: pointer;
      label,
      input {
        cursor: pointer !important;
      }
    }
    .radio {
      display: flex;
      align-items: center;
      pointer-events: none;
      input {
        margin-right: $contentDefaultIndent / 2;
        margin-bottom: 0;
      }
    }
  }
  .taskRepeatExplanation {
    margin-top: $contentDefaultIndent * 1.5;
    padding: $contentDefaultIndent * 1.5;
    border-radius: $uiBorderRadius;
    background-color: $uiBackgroundColor;
  }
}
